import { createApp } from 'vue'
import { createPinia } from 'pinia'
import AppLayout from '@/layouts/AppLayoutApp.vue'

import App from './App.vue'
import router from './router'
import LogRocket from 'logrocket'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'

import './assets/main.css'
import './assets/style.css'

library.add(fas)

const app = createApp(App)

if (import.meta.env.MODE === "production") {
    LogRocket.init('sprout/magic');
}

app.use(createPinia())
app.use(router)

app.config.errorHandler = function (err: any, vm, info) {
    // Handle the error globally
    console.error("Global error:", err);
    console.log("Vue instance:", vm);
    console.log("Error info:", info);

    return null
}

app
.component('font-awesome-icon', FontAwesomeIcon)
.component('AppLayout', AppLayout)
.mount('#app')
