import noAuthHttpClient from "./auth-http-client";

type Traits = {
    device_type: 'mobile' | 'desktop';
    browser?: string;
};

type IdentifyUserOptions = {
    searchSession?: string; // Making this optional again
    traits: Traits;
};

const identifyUser = (options: IdentifyUserOptions) => {
    const { searchSession, traits } = options;
    const data: any = {
        traits: traits,
    };

    // Only include searchSession if it's provided
    if (searchSession) {
        data.search_session = searchSession;
    }

    return noAuthHttpClient.post('/analytics/identify', data);
};

const trackUser = (event: string, searchSession: string, properties?: any) => { // Added searchSession parameter
    const data = {
        event: event,
        search_session: searchSession, // Change this line
        properties: properties,
    };
    return noAuthHttpClient.post('/analytics/track', data);
};

export {
    identifyUser,
    trackUser
};
