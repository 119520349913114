import { createRouter, createWebHistory } from 'vue-router'
import { authGuard } from './authGuard'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: "magic",
      component: () =>
        import(/* webpackChunkName "magic" */ "@/views/MagicReview.vue"),
      meta: {
        authRequired: false,
        layout: "AppLayoutApp",
        title: "Magic AI Hotel Reviews",
      },
      strict: true,
    },
    {
      path: '/review',
      name: "review",
      component: () =>
        import(/* webpackChunkName "magic" */ "@/views/MagicReview.vue"),
      meta: {
        authRequired: false,
        layout: "AppLayoutApp",
        title: "Magic Reviews",
      },
      strict: true,
    },
    {
      path: '/login',
      name: "login",
      component: () =>
        import(/* webpackChunkName "auth" */ "@/views/Auth.vue"),
      meta: {
        authRequired: false,
        layout: "AppLayoutApp",
        title: "Login",
      },
      strict: true,
    },
    {
      path: '/signup',
      name: "signup",
      component: () =>
        import(/* webpackChunkName "auth" */ "@/views/SignUp.vue"),
      meta: {
        authRequired: false,
        layout: "AppLayoutApp",
        title: "Sign Up",
      },
      strict: true,
    },
    {
      path: '/logout',
      name: "logout",
      component: () =>
        import(/* webpackChunkName "auth" */ "@/views/LogOut.vue"),
      meta: {
        authRequired: true,
        layout: "AppLayoutApp",
        title: "Logout",
      },
      strict: true,
    },
    {
      path: '/verify',
      name: "verify",
      component: () =>
        import(/* webpackChunkName "auth" */ "@/views/Verify.vue"),
      meta: {
        authRequired: false,
        layout: "AppLayoutApp",
        title: "Verify",
      },
      strict: true,
    },
    {
      path: '/reset-password',
      name: "reset-password",
      component: () =>
        import(/* webpackChunkName "auth" */ "@/views/ResetPassword.vue"),
      meta: {
        authRequired: true,
        layout: "AppLayoutApp",
        title: "Reset Password",
      },
      strict: true,
    },
    {
      path: "/manage",
      name: "manage-booking",
      component: () =>
        import(/* webpackChunkName "manage-booking" */ "@/views/ManageBooking.vue"),
      meta: {
        authRequired: false,
        layout: "AppLayoutApp",
        title: "Manage Booking",
      },
      strict: true,
    },
    {
      path: "/help",
      name: "help",
      component: () =>
        import(/* webpackChunkName "manage-booking" */ "@/views/Help.vue"),
      meta: {
        authRequired: false,
        layout: "AppLayoutApp",
        title: "Help & Support",
      },
      strict: true,
    },
    {
      path: "/confirm",
      name: "confirm",
      component: () =>
        import(/* webpackChunkName "confirm" */ "@/views/Confirm.vue"),
      meta: {
        authRequired: false,
        layout: "AppLayoutApp",
        title: "Sprout Magic Confirm",
      },
      strict: true,
    },
    {
      path: '/:pathMatch(.*)*',
      component: () =>
        import(/* webpackChunkName "notfound" */ "@/views/PathNotFoundView.vue"),
    },
    // {
    //   path: '/mobile-not-supported',
    //   name: 'MobileNotSupported',
    //   meta: {
    //     authRequired: false,
    //     layout: "AppLayoutApp",
    //     title: "Mobile Not Supported",
    //   },
    //   component: () =>
    //     import(/* webpackChunkName "mobilenotsupported" */ "@/views/MobileNotSupported.vue"),
    // },
  ]
})

router.beforeEach(authGuard);

router.onError((err) => {
  console.log("err", err)
  router.push("/404")
})

export default router
