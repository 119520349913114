import { identifyUser, trackUser } from '@/api/analytics.api';
import LogRocket from "logrocket";
import { reactive, watch } from "vue";

const initialState = {
    version: 1,
    user: null,
    elixir: {
        referralCode: ""
    }
} as any;

const store = {
    state: reactive({ ...initialState }),

    _initClean(): void {
        for (const [key, value] of Object.entries(initialState)) {
            this.state[key] = value;
        }
    },

    init(): void {
        if (localStorage.getItem("appState")) {
            const storedState = JSON.parse(
                localStorage.getItem("appState") as string
            );
            if (storedState.version !== initialState.version) {
                this._initClean();
            } else {
                for (const key of Object.keys(initialState)) {
                    this.state[key] = storedState[key];
                }
            }
        } else {
            this._initClean();
        }
    },

    setUser(user: any): void {
        this.state.user = user;
        if (user) {
            const isMobile = /Mobi|Android/i.test(window.navigator.userAgent);
            // Identify the user when Logged In
            identifyUser({
                traits: {
                    device_type: isMobile ? 'mobile' : 'desktop',
                },
            });
            // Track the "Logged In" event
            trackUser("Logged In", "", { // Not passing a searchSession as the user just logged in
                device_type: isMobile ? 'mobile' : 'desktop',
            });
            LogRocket.identify(user.id, {
                "email": user.email
            })
        }
    },

    setUserReferralCode(referralCode: any): void {
        this.state.elixir.referralCode = referralCode;
    },

    clearLocalStorage(): void {
        localStorage.clear();
        this._initClean();
    },
};

watch(store.state, (state: any) => {
    if (localStorage.getItem(`sb-${import.meta.env.VITE_APP_SUPABASE_PROJECT_REF_ID}-auth-token`)) {
        localStorage.setItem("appState", JSON.stringify(state));
    }
    else {
        localStorage.removeItem("appState")
    }
});

store.init();

export default store;
